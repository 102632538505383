<script setup lang="ts">
/**
 * @author Beka Chkhaidze
 */

import { ref, computed, onMounted, onBeforeUnmount, watch } from "vue";
import { Country } from "@/static/country";
import { useBookingStore } from "@/store/booking";

import { useMainStore } from "@/store/main/index";

import Input from "@/components/regularComponents/FormComponents/Input.vue";
import Dropdown from "./Dropdown.vue";

const props = withDefaults(
  defineProps<{
    componentKey?: number;
    modelValue?: string | null;
    dial: string | null;
  }>(),
  {
    modelValue: "",
  }
);

const emit = defineEmits(["update:modelValue", "update:dial", "submit"]);

const store = useMainStore();
const bookingStore = useBookingStore();

let dropdownTimeout: NodeJS.Timeout;
// const defaultDial = computed(() => {
//   return bookingStore.countryCodes?.find((el) => el.dial == props.dial) ||
//     bookingStore?.countryCodes?.[0];
// });

const isDropdownActive = ref<boolean>(false);
const countriesList = ref<HTMLUListElement>();
const country = ref<Country>();
const isFocused = ref<boolean>(false);

const isDesktop = computed(() => store.windowWidth > store.breakpoints.tablet);
const inputModel = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit("update:modelValue", newValue);
    emit("update:dial", country.value?.dial);
  },
});

const countryChange = (item: Country) => {
  bookingStore.dial = item.iso2;
  country.value = item;
  isDropdownActive.value = false;
  emit("update:dial", item.dial);
};

const toggleDropDown = (value: boolean) => {
  clearTimeout(dropdownTimeout);

  if (value) {
    isDropdownActive.value = value;
  } else {
    dropdownTimeout = setTimeout(() => {
      isDropdownActive.value = false;
    }, 200);
  }
};

const keyUpHandler = (e: KeyboardEvent) => {
  if (!isDropdownActive.value) return;

  const lis = countriesList.value?.querySelectorAll(".country-name");
  if (!lis) return;

  const match = Array.from(lis).find((el) =>
    el?.textContent?.startsWith(e.key?.toUpperCase())
  );

  if (!match) return;

  try {
    match?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  } catch (error) {
    match?.scrollIntoView(false);
  }
};
const onEvents = {
  // mouseenter() {
  //   if (isDesktop.value) {
  //     toggleDropDown(true);
  //   }
  // },
  // mouseleave() {
  //   if (isDesktop.value) {
  //     toggleDropDown(false);
  //   }
  // },
  click() {
    isDropdownActive.value = !isDropdownActive.value;
  },
};

watch(() => bookingStore.countryCodes, () => {
  country.value = (bookingStore.countryCodes?.find((el) => el.dial == props.dial) ||
    bookingStore?.countryCodes?.[0]) as Country
});

onMounted(() => {
  if (!isDesktop.value) return;
  document?.addEventListener("keyup", keyUpHandler);

  country.value = (bookingStore.countryCodes?.find((el) => el.dial == props.dial) ||
    bookingStore?.countryCodes?.[0]) as Country
});

onBeforeUnmount(() => {
  if (!isDesktop.value) return;
  document.removeEventListener("keyup", keyUpHandler);
});
</script>

<template>
  <div class="phone-input">
    <Input
      label="Phone Number"
      type="tel"
      v-model="inputModel"
      v-bind="{
        ...$attrs,
        clear: componentKey,
        regex: /[0-9]+/,
      }"
      @keypress.enter="$emit('submit')"
      @focusin="isFocused = true"
      @focusout="isFocused = false"
    >
      <template #left>
        <ClientOnly>
          <Dropdown
            v-on="onEvents"
            class="code-dropdown h-full"
            :active="isDropdownActive"
            v-click-outside="() => (isDropdownActive = false)"
          >
            <div class="code-dropdown__country">
              <div
                class="code-dropdown__country--label default-input__content__label op-6 text-left"
              >
                {{ $t("country") }}
              </div>
              <p class="code-dropdown__country--code no-wrap p-16">
                {{ country?.iso2 }} (+{{ country?.dial }})
              </p>
            </div>
            <template #body>
              <ul
                class="countries"
                ref="countriesList"
              >
                <!-- @mouseenter="toggleScroll(true)"
                @mouseleave="toggleScroll(false)" -->
                <li
                  v-for="(item, index) in bookingStore?.countryCodes"
                  :key="index"
                  :class="[
                    'p-16-regular country-item br-4 d-flex al-center',
                    { 'ev-none country-item--active op-8': item.iso2 == country?.iso2 },
                  ]"
                  @click.stop="countryChange(item)"
                >
                  <div class="country-flag d-flex">
                    <span>{{ item.unicode }}</span>
                  </div>
                  <p>
                    <span class="country-name">
                      {{ item.name }}
                    </span>
                    <span>(+{{ item.dial }})</span>
                  </p>
                </li>
              </ul>
            </template>
          </Dropdown>
        </ClientOnly>
      </template>
    </Input>
  </div>
</template>

<style lang="scss">
.no-wrap {
  white-space: nowrap;
}

.left-slot {
  position: relative;
  margin-right: 33px;
  &::after {
    content: "";
    background: $gray;
    width: 1px;
    height: 100%;
    display: block;
    position: absolute;
    right: -17px;
    top: 0;
  }

  .code-dropdown {
    padding: 0;

    &__country {
      &--code {
        line-height: 20px;
      }
    }

    .drop-content {
      padding-top: 26px;
      right: unset;
      left: -16px;
      top: 100%;

      &-in {
        min-width: 281px;
      }

      .country-item {
        padding: 14px 8px;

        &--active {
          // background-color: $dirtyWhite;
        }

        @include easeInOut(350ms, background-color);
        @include hover {
          // background-color: $dirtyWhite;
        }

        .country-flag {
          margin-right: 8px;
        }
      }
    }
  }
}
.phone-input {
  z-index: 10;
  position: relative;
  .code-dropdown {
    padding: 0;
    &__country {
      &--label {
        font-size: 24px;
        line-height: 32px;

        @include maxq(desktop-2k) {
          font-size: 16px;
          line-height: 24px;
        }
        @include maxq(desktop-lg) {
          font-size: 12px;
          line-height: 20px;
        }
      }

      &--code {
        font-size: 31px;
        line-height: 41px;

        @include maxq(desktop-2k) {
          font-size: 21px;
          line-height: 33px;
        }

        @include maxq(desktop-lg) {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }

    .drop-icons {
      margin: 0 16px;
    }
  }

  .custom-input {
    &__input {
      padding-left: 16px;

      input {
        padding-right: 16px;
      }
    }

    &__content {
      z-index: 12;
      position: relative;

      padding: 33px 31px 25px 31px;
      @include maxq(desktop-2k) {
        // padding: 30px 31px;
        padding: 15px 21px;
      }

      @include maxq(desktop-lg) {
        padding: 13px 31px;
      }
    }

    &__left-slot::after {
      content: "";
      background: $silver;
      width: 1px;
      height: 85%;
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .drop-content {
    left: -31px;
    top: 47px;

    &-in {
      min-width: 280px;
    }
  }

  .countries {
    max-height: 280px;
    overflow-y: auto;
    @include scrollbar;
  }
  .country-item {
    padding: 14px 8px;
    text-align: left;

    @include hover {
      background-color: $cultured;
    }

    &--active {
      background-color: $cultured;
    }
  }
}
</style>
